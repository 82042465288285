<template>
  <div @click="resultShow = false">
    <form-wizard
      color="#046D6D"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="حفظ البيانات"
      :back-button-text="$t('Add_Product.Previous')"
      :next-button-text="$t('Add_Product.Next')"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Main Data-->
      <tab-content :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end align-items-center"
            >
              <b-button variant="outline-warning" @click="rsetData"
                >تصحيح البيانات</b-button
              >
            </b-col>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t("Add_Product.General_Data") }}
              </h5>
              <small class="text-muted">
                {{ $t("Add_Product.Enter_Your_Product_Main_Data") }}
              </small>
            </b-col>
            <!-- report serial number -->
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="report serial number"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Report_Serial_Number')"
                  label-for="report serial number"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="report-serial"
                    v-model="reportSelect"
                    :state="reportSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="reportOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Brand -->
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="brand"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Brand')"
                  label-for="Brand"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="brand"
                    v-model="brandSelect"
                    :disabled="brandOptions.length == 0"
                    :state="brandSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="brandOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Item -->
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="item"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Item')"
                  label-for="Item"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="item"
                    v-model="itemSelect"
                    :disabled="brendSe"
                    :state="itemSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content :before-change="validationForm2">
        <validation-observer ref="accountRules2" tag="form">
          <b-row v-if="brandSelect != null && itemSelect != null" class="mb-5">
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  :src="itemSelect.cover"
                  height="250px"
                  class="product-img"
                />
              </div>
            </b-col>
            <b-col md="6" class="mt-auto mb-auto">
              <!-- Product Name -->
              <h6>
                <span style="color:#046D6D;"
                  >{{ $t("Add_Product.Name") }} :
                </span>
                <b-badge variant="primary">{{ itemSelect.name }}</b-badge>
              </h6>
              <br />
              <b-card-text class="item-company mb-0">
                <h6>
                  <span style="color:#046D6D;"
                    >{{ $t("Add_Product.Brand") }} :
                  </span>
                  <b-badge variant="primary"> {{ brandSelect.text }} </b-badge>
                </h6>
              </b-card-text>
              <br />
              <b-card-text
                v-if="categorySelect != null"
                class="item-company mb-0"
              >
                <h6>
                  <span style="color:#046D6D;"
                    >{{ $t("Add_Product.Category") }} :
                  </span>
                  <b-badge variant="primary">
                    {{ categorySelect.text }}
                  </b-badge>
                </h6>
              </b-card-text>
              <br />
              <h6>
                <span style="color:#046D6D;"
                  >{{ $t("Add_Product.Tag") }} :
                </span>
                <b-badge
                  variant="primary"
                  class="ml-3"
                  v-for="(item, index) in tagSelect"
                  :key="index"
                  v-html="item.text"
                ></b-badge>
              </h6>
              <br />
              <!-- Product Short Description -->
              <h6
                v-if="
                  itemSelect.short_description != null &&
                    itemSelect.short_description != ''
                "
              >
                <span style="color:#046D6D;"
                  >{{ $t("Add_Product.Short_Description") }} :
                </span>
              </h6>
              <b-card-text
                class="ml-3"
                v-html="itemSelect.short_description"
              ></b-card-text>
              <br />
              <!-- Product  Description -->
            </b-col>
          </b-row>
          <b-row>
            <!-- Category -->
            <b-col md="12" v-if="itemSelect != null">
              <h5
                v-if="
                  itemSelect.short_description != null &&
                    itemSelect.short_description != ''
                "
              >
                <span style="color:#046D6D;"
                  >{{ $t("Add_Product.Description") }} :
                </span>
              </h5>
              <b-card-text
                class="ml-3"
                v-html="itemSelect.description"
              ></b-card-text>
            </b-col>
            <b-col cols="12" class="mt-5">
              <b-card :title="$t('Add_Product.Attributes_and_Values')">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <!-- Row Loop -->
                      <b-row
                        v-for="(item, index) in productData.attributes"
                        :key="index"
                      >
                        <!-- Attrbuites -->
                        <b-col
                          md="2"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <p class="text-primary mb-0  font-weight-bold h4">
                            {{ attrSelect[index].text }}
                          </p>
                        </b-col>
                        <b-col v-if="productData.is_new == 'new'" md="4">
                          <validation-provider
                            #default="{ errors }"
                            name="values"
                            rules="required"
                          >
                            <b-form-group
                              label="Values"
                              label-for="values"
                              :state="errors.length > 0 ? false : null"
                            >
                              <v-select
                                multiple
                                :id="'values' + index"
                                v-model="valuesSelect[index]"
                                :clearable="false"
                                :state="
                                  valuesSelect[index] === null ? false : true
                                "
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="valuesOptions[index]"
                                :selectable="
                                  (option) =>
                                    !option.value.includes('select_value')
                                "
                                label="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col v-else md="4">
                          <validation-provider
                            v-if="
                              attrSelect[index].slug == 'اللون' ||
                                attrSelect[index].slug == 'الوزن' ||
                                attrSelect[index].slug == 'شريحه-الهاتف'
                            "
                            #default="{ errors }"
                            name="values"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('Add_Product.Value')"
                              label-for="values"
                              :state="errors.length > 0 ? false : null"
                            >
                              <v-select
                                :id="'values' + index"
                                v-model="valuesSelect[index]"
                                :clearable="false"
                                :state="
                                  valuesSelect[index] === null ? false : true
                                "
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="valuesOptions[index]"
                                :selectable="
                                  (option) =>
                                    !option.value.includes('select_value')
                                "
                                label="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                          <p class="text-secondary h5 mb-0 attr-text" v-else>
                            {{ valuesSelect[index].text }}
                          </p>
                        </b-col>
                        <!-- Remove Button -->
                        <!-- <b-col lg="2" md="3" class="mb-50">
                                                                                                                            <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="taxonomyremoveItem(index)">
                                                                                                                                <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                                <span>Remove</span>
                                                                                                                            </b-button>
                                                                                                                        </b-col> -->
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <!-- add new button -->
                  <!-- <b-col sm="2">
                                                                                                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="taxonomyrepeateAgain">
                                                                                                                    <feather-icon icon="PlusIcon" class="mr-25" />
                                                                                                                    <span> {{$t('Add_Product.Add_New')}}   </span>
                                                                                                                </b-button>
                                                                                                            </b-col> -->
                </b-row>
                <b-button
                  v-if="itemSelect != null"
                  class="mt-2"
                  @click="generate"
                  block
                  variant="success"
                >
                  {{
                    $route.params.type == "Portal"
                      ? " أضافة الإسعار والصور"
                      : "أضافة السعر"
                  }}
                </b-button>
              </b-card>
            </b-col>
            <b-col cols="12" v-if="varints.length > 0">
              <b-card :title="$t('Add_Product.Variants')" ref="varintsHeight">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <!-- Row Loop -->
                      <b-row v-for="(item, index) in varints" :key="index">
                        <b-col cols="12">
                          <b-row>
                            <b-col cols="12" class="">
                              <b-row>
                                <!-- price button -->
                                <b-col
                                  md="2"
                                  class="d-flex justify-content-center align-items-center"
                                >
                                  <b-button
                                    variant="warning"
                                    @click="setPrice(item, index)"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    v-b-modal.modal-prevent-closing
                                    >{{ $t("Add_Product.Add_Price") }}</b-button
                                  >
                                </b-col>
                                <!-- varint quantity -->
                                <b-col
                                  v-if="$route.params.type == 'Portal'"
                                  md="2"
                                  class="d-flex justify-content-center align-items-center"
                                >
                                  <b-form-group
                                    :label="$t('Add_Product.Quantity')"
                                    label-for="quantity"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="quantity"
                                      rules="required|regex:^[0]*[1-9][0-9]*$"
                                    >
                                      <b-form-input
                                        :id="'quantity' + index"
                                        :disabled="
                                          productData.is_new == 'used'
                                            ? true
                                            : false
                                        "
                                        min="0"
                                        type="number"
                                        v-model="
                                          productData.is_new == 'used'
                                            ? (item.vquantity = 1)
                                            : item.vquantity
                                        "
                                        :state="
                                          errors.length > 0 ? false : null
                                        "
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  md="4"
                                  v-show="
                                    item.salePrice > 0 && item.salePrice != null
                                  "
                                  class="text-dark"
                                >
                                  <p class="text-dark font-weight-bold">
                                    {{
                                      $route.params.type == "Portal"
                                        ? $t("Add_Product.Original_Price")
                                        : "سعر البائع"
                                    }}
                                    : {{ item.voriginal_price }} جنيه
                                  </p>
                                  <p
                                    class=" font-weight-bold"
                                    v-show="
                                      item.offerBDel.amount != 0 &&
                                        item.offerBDel.amount != '' &&
                                        item.offerBDel.amount != null
                                    "
                                  >
                                    الخصم:
                                    <b-badge
                                      varaint="primary"
                                      class="mx-1"
                                      v-if="
                                        item.offerBDel.type == 'fixed_price'
                                      "
                                      >{{ item.offerBDel.amount }} جنيه</b-badge
                                    >
                                    <b-badge variant="primary" v-else
                                      >{{ item.offerBDel.amount }}%</b-badge
                                    >
                                  </p>
                                  <p
                                    v-if="$route.params.type == 'Portal'"
                                    class="text-dark font-weight-bold"
                                  >
                                    {{ $t("Product_Index.Platform_Price") }} :
                                    {{ item.salePrice }} جنيه
                                  </p>
                                  <p class="text-dark font-weight-bold">
                                    {{
                                      $route.params.type == "Portal"
                                        ? $t("Product_Index.Platform_Profit")
                                        : "عمولة الخدمة"
                                    }}
                                    : {{ item.profit }} جنيه
                                  </p>
                                  <p
                                    v-if="$route.params.type != 'Portal'"
                                    class="text-dark font-weight-bold"
                                  >
                                    سعر البيع النهائى :
                                    {{
                                      parseFloat(item.voriginal_price) +
                                        parseFloat(item.profit)
                                    }}
                                    جنيه
                                  </p>
                                </b-col>
                                <b-col
                                  cols="12"
                                  v-if="$route.params.type == 'Portal'"
                                >
                                  <b-row>
                                    <!-- warranty -->
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Warranty"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('Add_Product.Warranty')"
                                          label-for="warranty"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            id="warranty"
                                            v-model="warrantySelect[index]"
                                            :state="
                                              warrantySelect[index] === null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="warrantyOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <!-- warranty duration -->
                                    <b-col
                                      md="6"
                                      v-if="
                                        warrantySelect[index] != null &&
                                          warrantySelect[index].value ==
                                            'seller'
                                      "
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Warranty Duration"
                                        rules="required"
                                      >
                                        <b-form-group
                                          label="Warranty Duration"
                                          label-for="Warranty Duration"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            id="Warranty-Duration"
                                            v-model="
                                              warrantyDurationSelect[index]
                                            "
                                            :state="
                                              warrantyDurationSelect[index] ===
                                              null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="warrantyDurationOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col
                                      md="6"
                                      v-else-if="
                                        warrantySelect[index] != null &&
                                          (warrantySelect[index].value ==
                                            'agency' ||
                                            warrantySelect[index].value ==
                                              'supplier')
                                      "
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="End Date"
                                        rules="required"
                                      >
                                        <b-form-group
                                          label="End Date"
                                          label-for="End date"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <flat-pickr
                                            v-model="item.warranty.end_date"
                                            class="form-control"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col
                                      md="6"
                                      v-if="
                                        warrantySelect[index] != null &&
                                          (warrantySelect[index].value ==
                                            'agency' ||
                                            warrantySelect[index].value ==
                                              'supplier')
                                      "
                                    >
                                      <b-form-group
                                        label="Warranty file"
                                        label-for="Warranty file"
                                      >
                                        <input
                                          type="file"
                                          class="form-control-file"
                                          multiple="multiple"
                                          name="Warranty_file"
                                          id="Warranty_file"
                                          ref="Warranty_file"
                                          change
                                          @change="fileUpload2($event, index)"
                                          accept="application/pdf, image/*"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <!-- Accessories -->
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Accessories"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('Add_Product.Accessories')"
                                          label-for="Accessories"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            multiple
                                            id="Accessories"
                                            mu
                                            v-model="accessoriesSelect"
                                            :state="
                                              accessoriesSelect === null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="accessoriesOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                                <b-col
                                  v-if="$route.params.type == 'Portal'"
                                  cols="12"
                                >
                                  <b-row>
                                    <b-col cols="12">
                                      <p>فيديو لعرض الموبايل</p>
                                    </b-col>
                                    <b-col
                                      cols="2"
                                      md="1"
                                      class="d-flex justify-content-center align-items-center p-0"
                                    >
                                      <b-img
                                        class="w-100"
                                        src="@/assets/images/ProductImages/MobileSides.png"
                                      ></b-img>
                                    </b-col>
                                    <b-col cols="10" md="4">
                                      <b-form-group
                                        class="block"
                                        label="فديو (.mp4), يجب أن يكون حجم الفديو ١٥ ميجابايت او أقل "
                                        label-for="فديو"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="فديو"
                                          rules="required|filesize"
                                        >
                                          <b-form-file
                                            type="file"
                                            class="form-control-file"
                                            name="file"
                                            id="file"
                                            ref="file"
                                            v-model="files"
                                            :state="errors ? false : null"
                                            accept="video/mp4"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col
                                  v-if="$route.params.type == 'Portal'"
                                  cols="12"
                                >
                                  <hr />
                                </b-col>
                                <b-col
                                  v-if="$route.params.type == 'Portal'"
                                  cols="12"
                                >
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col cols="12">
                                          <div>
                                            <b-form
                                              ref="vImagesform"
                                              :style="{ height: trHeight }"
                                              class="repeater-form"
                                              @submit.prevent="
                                                vImagesRepeateAgain(item)
                                              "
                                            >
                                              <!-- Row Loop -->
                                              <b-row
                                                v-for="(im,
                                                ind) in item.vimages2"
                                                :id="im + ind"
                                                :key="im + ind"
                                                ref="row"
                                              >
                                                <b-col cols="12">
                                                  <p>{{ im.name }}</p>
                                                </b-col>
                                                <!-- Image Reapeted -->
                                                <b-col
                                                  v-if="ind == 0"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/Face.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  v-if="ind == 1"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/Back.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  v-if="ind == 2"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/Bottom.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  v-if="ind == 3"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/Top.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  v-if="ind == 4"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/LeftSide.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  v-if="ind == 5"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/RightSide.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  v-if="ind == 6"
                                                  cols="2"
                                                  md="1"
                                                  class="d-flex justify-content-center align-items-center"
                                                >
                                                  <b-img
                                                    src="@/assets/images/ProductImages/Group521.png"
                                                  ></b-img>
                                                </b-col>
                                                <b-col
                                                  cols="10"
                                                  md="4"
                                                  class="mt-auto mb-auto"
                                                >
                                                  <b-form-group
                                                    label="Image"
                                                    :label-for="'image' + ind"
                                                  >
                                                    <validation-provider
                                                      #default="{ errors }"
                                                      :name="'image' + ind"
                                                      rules="required"
                                                    >
                                                      <b-form-file
                                                        @change="
                                                          uploadImage3(
                                                            $event,
                                                            item,
                                                            ind
                                                          )
                                                        "
                                                        v-model="
                                                          item.vimages3[ind]
                                                            .vname
                                                        "
                                                        :state="
                                                          errors.length > 0
                                                            ? false
                                                            : null
                                                        "
                                                        :id="
                                                          'image' +
                                                            ind +
                                                            index +
                                                            ind
                                                        "
                                                        accept="image/*"
                                                      />
                                                      <small
                                                        class="text-danger"
                                                        >{{ errors[0] }}</small
                                                      >
                                                    </validation-provider>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col cols="12" md="2">
                                                  <b-media class="mb-1 mr-auto">
                                                    <b-img
                                                      v-if="
                                                        item.vimages2[ind]
                                                          .vname != null &&
                                                          item.vimages2[ind]
                                                            .vname != ''
                                                      "
                                                      class="img-thumbnail"
                                                      ref="previewEl"
                                                      :src="
                                                        item.vimages2[ind].vname
                                                      "
                                                      height="100px"
                                                    />
                                                  </b-media>
                                                </b-col>
                                                <!-- Remove Button -->
                                                <b-col
                                                  cols="3"
                                                  class="mt-auto mb-auto"
                                                >
                                                  <b-button
                                                    v-ripple.400="
                                                      'rgba(234, 84, 85, 0.15)'
                                                    "
                                                    v-if="ind > 6"
                                                    variant="outline-danger"
                                                    class="mt-0 mt-md-2"
                                                    @click="
                                                      vImagesRemoveItem(
                                                        ind,
                                                        item
                                                      )
                                                    "
                                                  >
                                                    <feather-icon
                                                      icon="XIcon"
                                                      class="mr-25"
                                                    />
                                                    <span>Remove</span>
                                                  </b-button>
                                                </b-col>
                                                <b-col cols="12">
                                                  <hr />
                                                </b-col>
                                              </b-row>
                                            </b-form>
                                          </div>
                                        </b-col>
                                        <!-- add new button -->
                                        <b-col cols="12">
                                          <b-button
                                            v-ripple.400="
                                              'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="outline-primary"
                                            block
                                            @click="vImagesRepeateAgain(item)"
                                          >
                                            <feather-icon
                                              icon="PlusIcon"
                                              class="mr-25"
                                            />
                                            <span>{{
                                              $t("Add_Product.Add_New_Image")
                                            }}</span>
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-col>
                            <!-- Remove Button -->
                            <!-- <b-col cols="2" class="mb-50">
                                                                                                                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" class=" m-2 border-0" @click="pricesremoveItem(index)">
                                                                                                                        <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                        <span>Remove</span>
                                                                                                                    </b-button>
                                                                                                                </b-col> -->
                            <b-col
                              v-if="$route.params.type == 'Portal'"
                              cols="12"
                            >
                              <hr />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <!-- add Price Model -->
                <b-modal
                  id="modal-prevent-closing"
                  cancel-variant="outline-secondary"
                  @ok="handleOk"
                  :ok-title="$t('Product_Index.Add')"
                  :cancel-title="$t('Product_Index.Close')"
                  centered
                  :title="$t('Add_Product.Add_Price')"
                  size="lg"
                >
                  <validation-observer ref="addPrice">
                    <b-form @submit.stop.prevent="addPriceRate">
                      <b-row>
                        <!-- varint price -->
                        <b-col md="6">
                          <b-form-group
                            :label="$t('Add_Product.Price')"
                            label-for="price"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="price"
                              rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                            >
                              <b-form-input
                                v-model="variantItem.voriginal_price"
                                min="0"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- varint Amount -->
                        <b-col v-if="$route.params.type == 'Portal'" md="6">
                          <b-form-group
                            :label="$t('Add_Product.Amount')"
                            label-for="amount"
                          >
                            <b-form-input
                              min="1"
                              type="number"
                              v-model="variantItem.offerBDel.amount"
                              placeholder="enter amount"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="
                            variantItem.offerBDel.amount > 0 &&
                              $route.params.type == 'Portal'
                          "
                          md="12"
                        >
                          <b-row>
                            <b-col>
                              <app-collapse
                                id="faq-payment-qna"
                                type="margin"
                                class="mt-0 mb-0"
                              >
                                <app-collapse-item
                                  :key="variantIndex"
                                  title="Click To Customize Offer"
                                >
                                  <b-row>
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Offer Type"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="
                                            $t('Product_Index.Offer_Type')
                                          "
                                          label-for="Offer Type"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            id="Offer-Type"
                                            v-model="offerTypeSelect"
                                            :state="
                                              offerTypeSelect === null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="offerTypeOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                      <b-form-group
                                        :label="
                                          $t('Product_Index.Users_Number')
                                        "
                                        label-for="User-Numbers"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="user numbers"
                                          rules="required|regex:^[0]*[1-9][0-9]*$"
                                        >
                                          <b-form-input
                                            min="1"
                                            id="User-Numbers"
                                            type="number"
                                            disabled
                                            v-model="
                                              variantItem.offerBDel.usernumbers
                                            "
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                            :placeholder="
                                              $t('Product_Index.Users_Number')
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="start date"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="
                                            $t('Product_Index.Start_Date')
                                          "
                                          label-for="start date"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <flat-pickr
                                            v-model="
                                              variantItem.offerBDel.startdate
                                            "
                                            class="form-control"
                                            :config="{
                                              enableTime: true,
                                              dateFormat: 'Y-m-d H:i',
                                              minDate: 'today',
                                            }"
                                          />
                                          <b-form-invalid-feedback
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          >
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="end date"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('Product_Index.End_Date')"
                                          label-for="end date"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <flat-pickr
                                            :disabled="
                                              variantItem.offerBDel.startdate ==
                                                null ||
                                                variantItem.offerBDel
                                                  .startdate == ''
                                            "
                                            v-model="
                                              variantItem.offerBDel.enddate
                                            "
                                            class="form-control"
                                            :config="{
                                              enableTime: true,
                                              dateFormat: 'Y-m-d H:i',
                                              minDate:
                                                variantItem.offerBDel.startdate,
                                            }"
                                          />
                                          <b-form-invalid-feedback
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          >
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-row>
                                </app-collapse-item>
                              </app-collapse>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row  v-if="$route.params.type == 'In-Store'">
                      <h5 class="text-left m-1"> طريقة الدفع </h5>
                        <validation-provider
                                #default="{ errors }"
                                name="payment Method"
                                rules="required"
                                class="w-100"
                                v-if="isAnyProviderValid"
                              >
                        <b-form-radio-group v-model="variantItem.payment_provider_id" name="paymentOption" class="w-100">
                        <b-col md="12"   v-if="bankInstallmentProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="bankInstallmentProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                    <div class="d-flex justify-content-between w-100 align-items-center">
                                      <div class="d-flex align-items-left">
                                        <img  src="@/assets/images/icons/bank_installment.svg" alt="Bank Installment Icon" class="icon mr-1 max-width-42">
                                        <span class="mr-1 text-left text-secondary"> تقسيط بنكي</span>
                                      </div>
                                      <div class="font-small-2">
                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = bankInstallmentProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * bankInstallmentProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + bankInstallmentProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              bankInstallmentProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * bankInstallmentProvider.rate) / 100
                                                : bankInstallmentProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>


                                        
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_CIB'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'CIB'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/cib.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_CIB'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Mashreq'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Mashreq'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/mashreq.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Mashreq'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Nbd'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Nbd'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Nbd'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_fab'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Fab'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Fab'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_NBK'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'NBK'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbk.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_NBK'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    


                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                        
                      </b-col>
                        <b-col md="12">
                       
                            <h5 class="text-left m-1"> تقسيط الشركات </h5>
                              <b-card   class="change-padding p-0"  v-if="valuProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="valuProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center ">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/valu_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     <!-- Scrolling Container -->
                                     <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_valu'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'valu'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_valu'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="contactProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="contactProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/contact_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price =  contactProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * contactProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + contactProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              contactProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * contactProvider.rate) / 100
                                                : contactProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_contact'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'contact'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_contact'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="getgoProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="getgoProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/getgo.svg" alt="getgo Icon" class="icon mr-1 w-100 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = getgoProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * getgoProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + getgoProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              getgoProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * getgoProvider.rate) / 100
                                                : getgoProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_getgo'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'getgo'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_getgo'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="halanProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="halanProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/halan.svg" alt="halan Icon" class="icon mr-1  max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = halanProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * halanProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + halanProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              halanProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * halanProvider.rate) / 100
                                                : halanProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_halan'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'halan'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_halan'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            
                            <b-card  class="change-padding p-0" v-if="amanProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="amanProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/aman_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price =  amanProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * amanProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + amanProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              amanProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * amanProvider.rate) / 100
                                                : amanProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     <!-- Scrolling Container -->
                                     <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_aman'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'aman'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_aman'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card class="change-padding p-0" v-if="shoolaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="shoolaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/sahoola_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = shoolaProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * shoolaProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + shoolaProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              shoolaProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * shoolaProvider.rate) / 100
                                                : shoolaProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                    
                                        <!-- Scrolling Container -->
                                        <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_souhoola'" >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'souhoola'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_souhoola'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card class="change-padding p-0" v-if="forsaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="forsaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/forsa_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = forsaProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * forsaProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + forsaProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              forsaProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * forsaProvider.rate) / 100
                                                : forsaProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                    
                                      
                                     <!-- Scrolling Container -->
                                     <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_forsa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'forsa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_forsa'"
                                          class="installment-plan-item mb-0"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>

                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>


                      </b-col>


                      <b-col md="12"  class="mt-3" v-if="visaProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="visaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center">
                                    <div class="d-flex justify-content-between w-100 align-items-center">
                                      <div class="d-flex align-items-left">
                                        <img  src="@/assets/images/icons/credit_card_icon.svg" alt="Company Icon" class="icon">
                                        <span class="mr-1 text-secondary">بطاقه ائتمان</span>
                                      </div>
                                      <div class="font-small-2">
                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = visaProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * visaProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + visaProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              visaProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * visaProvider.rate) / 100
                                                : visaProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     
                                       <!-- Scrolling Container -->
                                       <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_visa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'visa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_visa'"
                                          class="installment-plan-item mb-0"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                      </b-col>

                    </b-form-radio-group>

                    <b-form-invalid-feedback
                          :state="
                            errors.length > 0 ? false : null
                          ">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>
                  
                   <b-col md="12" v-else>
                      <div >
                        <p class="text-danger text-center">لا تتوفر طريقة دفع  للسعر المحدد.</p>
                      </div>
                   </b-col>

                    </b-row>
                    </b-form>
                  </validation-observer>
                  <!-- error handelr -->
                  <b-alert
                    v-height-fade.appear
                    fade
                    :show="showDismissibleAlert2"
                    @dismissed="showDismissibleAlert2 = false"
                    variant="danger"
                  >
                    <h4 class="alert-heading">
                      Alert
                    </h4>
                    <div class="alert-body">
                      <ul v-for="(values, index) in errors_back2" :key="index">
                        <li v-for="(value, valIndex) in values" :key="valIndex">
                          {{ value }}
                        </li>
                      </ul>
                    </div>
                  </b-alert>
                </b-modal>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
  import { FormWizard, TabContent } from "vue-form-wizard";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";

  import { codeIcon } from "./code2";
  import { required, filesize } from "@validations";
  import flatPickr from "vue-flatpickr-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";

  import { heightTransition } from "@core/mixins/ui/transition";

  export default {
    components: {
      FormWizard,
      TabContent,

      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      flatPickr,
      Cleave,
    },

    mixins: [heightTransition],
    watch: {
      "productData.serialnumber": function() {
        this.currentPage = 0;
        this.serialsNumber.length = 0;
      },
      reportSelect: function() {
        if (this.reportSelect != null) {
        this.errors_back = [];
        this.showDismissibleAlert = false;
          this.productData.serialnumber = this.reportSelect.value;
          this.brandSelect = null;
          this.itemSelect = null;
          this.brandOptions = [];
          this.itemOptions = [];
          axios
            .get(`/products/reports/${this.reportSelect.value}/valid-data`)
            .then((result) => {
              const data = result.data.data;
              this.initalData = result.data.data;
              if (data.items.length == 1) {
                // Set brand
                for (let ind in data.brands) {
                  this.brandOptions.push({
                    text: data.brands[ind].name,
                    value: data.brands[ind].id.toString(),
                  });
                  this.brandSelect = {
                    text: data.brands[ind].name,
                    value: data.brands[ind].id.toString(),
                  };
                }
                this.brendSe = false;

                // set item
                for (let ind in data.items) {
                  this.itemOptions.push({
                    text: data.items[ind].name,
                    value: data.items[ind].id.toString(),
                  });

                  this.itemSelect = {
                    text: data.items[ind].name,
                    value: data.items[ind].id.toString(),
                  };
                }

                // reset attrbibutes
                this.attrOptions = [];
                this.attrSelect = [];
                this.productData.attributes = [];
                this.valuesOptions = [];
                this.valuesSelect = [];
                this.combTextArr = [];
                this.combValuesArr = [];
                this.attr2Options = [];
                // set attrbibutes
                for (let attr in data.attributes) {
                  if (data.attributes[attr].translation != null) {
                    this.attrOptions.push({
                      value: data.attributes[attr].id.toString(),
                      text: data.attributes[attr].translation.name,
                      slug: data.attributes[attr].translation.slug,
                    });
                    this.attrSelect.push({
                      value: data.attributes[attr].id.toString(),
                      text: data.attributes[attr].translation.name,
                      slug: data.attributes[attr].translation.slug,
                    });
                    this.productData.attributes.push({
                      id: "",
                      values: [],
                    });
                    this.valuesOptions.push([]);
                    for (let val in data.attributes[attr].values) {
                      this.valuesOptions[attr].push({
                        text:
                          data.attributes[attr].values[val].translation.name,
                        value: data.attributes[attr].values[val].id.toString(),
                      });
                    }
                    if (
                      data.attributes[attr].values.filter(
                        (e) => e.valid === true
                      ).length > 0
                    ) {
                      for (let val in data.attributes[attr].values) {
                        if (
                          data.attributes[attr].values[val].valid == true ||
                          data.attributes[attr].values.length == 1
                        ) {
                          this.valuesSelect.push({
                            text:
                              data.attributes[attr].values[val].translation
                                .name,
                            value: data.attributes[attr].values[
                              val
                            ].id.toString(),
                          });
                        }
                      }
                    } else {
                      this.valuesSelect.push([]);
                    }
                  }
                  this.combTextArr.push([]);
                  this.combValuesArr.push([]);
                }
                this.attr2Options = this.attrOptions;

                // Set Category
                this.categorySelect = {
                  text: data.items[0].category_name,
                  value: data.items[0].category_id.toString(),
                };
                (this.itemSelect.name = data.items[0].name),
                  (this.itemSelect.description = data.items[0].description),
                  (this.itemSelect.short_description =
                    data.items[0].short_description),
                  (this.itemSelect.status = data.items[0].status),
                  (this.itemSelect.cover = data.items[0].cover);
                this.tagSelect = [];
                for (let indeex in data.items[0].tags) {
                  this.tagSelect.push({
                    text: data.items[0].tags[indeex].name,
                    value: data.items[0].tags[indeex].id.toString(),
                  });
                }
              } else if (data.items.length > 1) {
                for (let ind in data.brands) {
                  this.brandOptions.push({
                    text: data.brands[ind].name,
                    value: data.brands[ind].id.toString(),
                  });
                  this.brandSelect = {
                    text: data.brands[ind].name,
                    value: data.brands[ind].id.toString(),
                  };
                }
                this.brendSe = false;
                // set item
                for (let ind in data.items) {
                  this.itemOptions.push({
                    text: data.items[ind].name,
                    value: data.items[ind].id.toString(),
                  });
                }
              } else {
                for (let ind in data.brands) {
                  this.brandOptions.push({
                    text: data.brands[ind].name,
                    value: data.brands[ind].id.toString(),
                  });
                }
              }
            })
            .catch((err) => {
              // console.log(err)
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        } else {
          this.brendSe = true;
          this.brandSelect = null;
          this.itemSelect = null;
          this.brandOptions = [];
          this.itemOptions = [];
        }

        // if (this.brandSelect != null && this.reportSelect != null) {
        //   this.brendSe = false;
        //   this.itemSelect = null;
        //   this.getItems();
        // } else {
        //   this.brendSe = true;
        //   this.itemSelect = null;
        // }
      },
      brandSelect: function() {
        if (
          this.brandSelect != null &&
          this.reportSelect != null &&
          this.brandOptions.length > 1
        ) {
          this.brendSe = false;
          this.itemSelect = null;
          this.getItems();
        } else if (this.brandOptions.length == 1) {
          this.brendSe = false;
          // this.itemSelect = null;
        } else {
          this.brendSe = true;
          this.itemSelect = null;
        }
      },
      itemSelect: function() {
        if (
          this.itemSelect != null &&
          (this.initalData.attributes.length == 0 ||
            this.initalData.attributes == null)
        ) {
          this.getAttrValue();
        }
      },
      attrSelect: function() {
        this.getOptions();
      },
      valuesSelect: function() {},
    },
    data() {
      return {
        video_size: false,
        video: null,
        errors_back: [],
        initalData: null,
        showDismissibleAlert: false,
        errors_back2: [],
        showDismissibleAlert2: false,
        required,
        filesize,
        itemSe: true,
        subActive: true,
        offerTypeSelect: null,
        offerTypeOptions: [
          {
            text: "Fixed Price",
            value: "fixed_price",
          },
          {
            text: "Percentage",
            value: "percentage",
          },
        ],
        codeIcon,
        noResult: false,
        resultShow: false,
        productData: {
          serialnumber: null,
          is_new: "used",
          category_id: "",
          brand_id: "",
          item_id: "",
          tags: [],
          status: "",
          translations: [
            {
              locale: "",
              name: "",
              short_description: "",
              description: "",
            },
            {
              locale: "",
              name: "",
              short_description: "",
              description: "",
            },
          ],
          attributes: [],
          variants: [],
        },
        currentPage: 0,
        serialsNumber: [],
        brandSelect: null,
        brandOptions: [],
        itemSelect: null,
        itemOptions: [],
        brendSe: true,
        categorySelect: null,
        categoryOptions: [],
        tagSelect: [],
        tagOptions: [],
        accessoriesSelect: null,
        accessoriesOptions: [
          {
            value: "select_value",
            text: "Select Value",
          },
        ],
        valuesSelect: [],
        valuesOptions: [
          [
            {
              value: "select_value",
              text: "Select Value",
            },
          ],
        ],
        valuesID: [],
        combTextArr: [[]],
        combValuesArr: [[]],
        varints: [],
        attrSelect: [],
        attrOptions: [],
        attr2Options: [],
        reportOptions: [],
        reportSelect: null,
        files: null,
        warrantyOptions: [
          {
            text: "موبايل مصر",
            value: "seller",
          },
          {
            text: "الوكيل",
            value: "agency",
          },
          {
            text: "الموزع",
            value: "supplier",
          },
        ],
        warrantyDurationSelect: [],
        warrantyDurationOptions: [
          {
            text: "7 يوم",
            value: "7",
          },
          {
            text: "14 يوم",
            value: "14",
          },
          {
            text: "30 يوم",
            value: "30",
          },
        ],
        warrantySelect: [],
        variantItem: {
          offerHide: false,
          offerBDel: {
            type: null,
            amount: "",
            startdate: null,
            enddate: null,
            usernumbers: "1",
          },
          voriginal_price: "",
        },
        userData: null,
        PriceRates: [],
        InstallmentPlans: []

      };
    },
    computed: {
      // Ensure `variantItem.voriginal_price` is reactive
      
      variantPrice() {
        // console.log('this.variantItem.voriginal_price',this.variantItem.voriginal_price);
        if(this.$route.params.type == 'In-Store'){
          return Number(this.variantItem.voriginal_price);
        }
      },

      valuProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "valu" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice

        );
        return provider  ? provider : null;
      },

      
      bankInstallmentProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "bank-installment" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      contactProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "contact" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      amanProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "aman" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      shoolaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "souhoola" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      forsaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "forsa" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      visaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "visa" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },


      getgoProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "getgo" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      halanProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "halan" &&
          item.service_type == 'used' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      // New computed property to check if any provider is valid
      isAnyProviderValid() {
        return this.valuProvider || this.contactProvider || this.amanProvider ||
              this.shoolaProvider || this.forsaProvider || this.visaProvider || this.bankInstallmentProvider || this.halanProvider || this.getgoProvider;
      }
    
  },
    methods: {
      rsetData() {
        this.errors_back = [];
        this.showDismissibleAlert = false;
        this.brandSelect = null;
        this.itemSelect = null;
        this.brandOptions = [];
        this.itemOptions = [];
        axios
          .get("products/create")
          .then((result) => {
            const data = result.data.data;
            // set Brands

            for (let ind in data.brands) {
              this.brandOptions.push({
                text: data.brands[ind].translation.name,
                value: data.brands[ind].id.toString(),
              });
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      formSubmitted() {

        for (let off in this.varints) {
        if (
          this.varints[off].voriginal_price == null ||
          this.varints[off].voriginal_price == ''
        ) {

          this.errors_back = [];
          this.errors_back.push({
              error: "Price field is required",
            });
          this.showDismissibleAlert = true;
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'خطأ! ( التحقق من سعر المنتج )',
            showConfirmButton: false,
            timer: 1500,
          })
          return
        }
      }


        this.errors_back = [];
        this.showDismissibleAlert = false;
        this.productData.item_id = this.itemSelect.value;
        for (let index in this.warrantySelect) {
          this.varints[index].warranty.type = this.warrantySelect[index].value;
          if (this.varints[index].warranty.type == "seller") {
            this.varints[index].warranty.duration = this.warrantyDurationSelect[
              index
            ].value;
          }
        }
        this.productData.accessories = [];
        for (let accessory in this.accessoriesSelect) {
          this.productData.accessories.push(
            this.accessoriesSelect[accessory].value
          );
        }
        for (let vari in this.varints) {
          const datav = this.varints[vari].vtranslations;
          for (let tran in datav) {
            datav[tran].vlocale = datav[tran].vlocale1.value;
          }
        }
        for (let vari in this.varints) {
          const varint = this.varints[vari];
          for (let img in varint.vimages2) {
            if (this.isString(varint.vimages2[img].vname)) {
              if (varint.vimages2[img].vname.includes(",")) {
                varint.vimages[img].vname = null;
                var imageC = varint.vimages2[img].vname
                  .split(",")
                  .map((item) => item.trim());
                varint.vimages[img].vname = imageC[1];
              }
            }
          }
        }
        for (let vari in this.varints) {
          const varint = this.varints[vari];
          if (varint.values.includes("-")) {
            varint.values = varint.values.split("-").map((item) => item.trim());
          }
        }
        for (let off in this.varints) {
          if (
            this.varints[off].offerBDel.amount != 0 &&
            this.varints[off].offerBDel.amount != null &&
            this.varints[off].offerBDel.amount != ""
          ) {
            delete this.varints[off].offer;
          } else {
            this.varints[off].offer.amount = this.varints[off].offerBDel.amount;
            this.varints[off].offer.type = this.varints[off].offerBDel.type;
            this.varints[off].offer.startdate = this.varints[
              off
            ].offerBDel.startdate;
            this.varints[off].offer.enddate = this.varints[
              off
            ].offerBDel.enddate;
            this.varints[off].offer.usernumbers = this.varints[
              off
            ].offerBDel.usernumbers;
          }
        }
        this.productData.variants = this.varints;
        const Product_data = this.productData;
        // for (let vari in Product_data.variants) {
        //     const variant = Product_data.variants[vari]
        //     delete variant.vname
        //     // for (let varia in variant.vtranslations){
        //     //     delete  variant.vtranslations[varia].vlocale1
        //     // }
        // }
        let formData = new FormData();
        if (this.files != null && this.$route.params.type == "Portal") {
          formData.append("video", this.files);
        }
        formData.append(
          "is_portal",
          this.$route.params.type == "Portal" ? 1 : 0
        );
        if (this.$route.params.type == "Portal") {
          for (var i = 0; i < Product_data.accessories.length; i++) {
            formData.append(
              "accessories[" + i + "]",
              Product_data.accessories[i]
            );
          }
        }

        formData.append("item_id", Product_data.item_id);
        formData.append("is_new", Product_data.is_new);
        formData.append("category_id", this.$route.params.category_id);
        // formData.append('attributes', Product_data.attributes)
        for (var i = 0; i < Product_data.attributes.length; i++) {
          formData.append(
            "attributes[" + i + "][id]",
            Product_data.attributes[i].id
          );
          for (var z = 0; z < Product_data.attributes[i].values.length; z++) {
            formData.append(
              "attributes[" + i + "][values][" + z + "]",
              Product_data.attributes[i].values[z]
            );
          }
        }
        // formData.append('variants', Product_data.variants)
        for (var i = 0; i < Product_data.variants.length; i++) {
          formData.append(
            "variants[" + i + "][voriginal_price]",
            Product_data.variants[i].voriginal_price
          );
          if(this.$route.params.type == 'In-Store'){
            formData.append(
              'variants[' + i + '][payment_provider_id]',
              Product_data.variants[i].payment_provider_id
            )
          }
         
          formData.append(
            "variants[" + i + "][vquantity]",
            Product_data.variants[i].vquantity
          );
          formData.append(
            "variants[" + i + "][vis_new]",
            Product_data.variants[i].vis_new
          );
          if (this.$route.params.type == "Portal") {
            formData.append(
              "variants[" + i + "][warranty][type]",
              Product_data.variants[i].warranty.type
            );
            if (Product_data.variants[i].warranty.type == "seller") {
              formData.append(
                "variants[" + i + "][warranty][duration]",
                Product_data.variants[i].warranty.duration
              );
            } else {
              formData.append(
                "variants[" + i + "][warranty][end_date]",
                Product_data.variants[i].warranty.end_date
              );
              formData.append(
                "variants[" + i + "][warranty][agency_file]",
                Product_data.variants[i].warranty.agency_file
              );
            }
          }
          if (this.productData.is_new == "used") {
            formData.append(
              "variants[" + i + "][report_serial_number]",
              Product_data.serialnumber
            );
          }
          if (this.$route.params.type == "Portal") {
            if (Product_data.variants[i].vimages != null) {
              for (
                var z = 0;
                z < Product_data.variants[i].vimages.length;
                z++
              ) {
                formData.append(
                  "variants[" + i + "][vimages][" + z + "][vname]",
                  Product_data.variants[i].vimages[z].vname
                );
              }
            }
          }
          for (var z = 0; z < Product_data.variants[i].values.length; z++) {
            formData.append(
              "variants[" + i + "][values][" + z + "]",
              Product_data.variants[i].values[z]
            );
          }
          for (
            var z = 0;
            z < Product_data.variants[i].vtranslations.length;
            z++
          ) {
            formData.append(
              "variants[" + i + "][vtranslations][" + z + "][vlocale]",
              Product_data.variants[i].vtranslations[z].vlocale
            );
            formData.append(
              "variants[" +
                i +
                "][vtranslations][" +
                z +
                "][vshort_description]",
              Product_data.variants[i].vtranslations[z].vshort_description
            );
            formData.append(
              "variants[" + i + "][vtranslations][" + z + "][vdescription]",
              Product_data.variants[i].vtranslations[z].vdescription
            );
          }
          if (this.$route.params.type == "Portal") {
            if (
              this.varints[i].offerBDel.amount != 0 &&
              this.varints[i].offerBDel.amount != null &&
              this.varints[i].offerBDel.amount != ""
            ) {
              formData.append(
                "variants[" + i + "][offer][amount]",
                Product_data.variants[i].offerBDel.amount
              );
              formData.append(
                "variants[" + i + "][offer][type]",
                Product_data.variants[i].offerBDel.type
              );
              formData.append(
                "variants[" + i + "][offer][startdate]",
                Product_data.variants[i].offerBDel.startdate
              );
              formData.append(
                "variants[" + i + "][offer][enddate]",
                Product_data.variants[i].offerBDel.enddate
              );
              formData.append(
                "variants[" + i + "][offer][usernumbers]",
                Product_data.variants[i].offerBDel.usernumbers
              );
            }
          }
        }
        for (let [key, value] of formData) {
        }
        axios
          .post("products", formData)
          .then((result) => {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
            if(result.data.data.variants[0].is_new){

              this.$router.push({
  
                path:
                  "/New/ShowProduct/variant/show/" +
                  result.data.data.variants[0].id,
              });

            }else{
              
              this.$router.push({
                
                path:
                  "/Used/ShowProduct/variant/show/" +
                  result.data.data.variants[0].id,
              });

            }
          })
          .catch((err) => {
            this.$swal({
              position: "center",
              icon: "error",
              title: "Error!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      setPrice(item, index) {
        this.errors_back2 = [];
        this.showDismissibleAlert2 = false;
        this.variantItem = item;
        this.variantIndex = index;
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.addPriceRate();
      },
      async addPriceRate() {
        this.showDismissibleAlert2 = false;
        const isValid = await this.$refs.addPrice.validate();
        if (isValid) {
          var sendData = null;
          if (
            this.variantItem.offerBDel.amount == 0 ||
            this.variantItem.offerBDel.amount == null ||
            this.variantItem.offerBDel.amount == ""
          ) {
            sendData = {
              original_price: this.variantItem.voriginal_price,
              item_id: this.itemSelect.value,
              product_type: "used",
              is_portal: this.$route.params.type == "Portal" ? true : false,
              payment_provider_id: this.$route.params.type == "In-Store" ? this.variantItem.payment_provider_id : null,
            };
          } else {
            sendData = {
              original_price: this.variantItem.voriginal_price,
              item_id: this.itemSelect.value,
              product_type: "used",
              offer_type:
                this.offerTypeSelect == null
                  ? null
                  : this.offerTypeSelect.value,
              offer_amount:
                this.variantItem.offerBDel.amount == 0
                  ? null
                  : this.variantItem.offerBDel.amount,
              is_portal: this.$route.params.type == "Portal" ? true : false,
              payment_provider_id: this.$route.params.type == "In-Store" ? this.variantItem.payment_provider_id : null,
            };
          }
          axios
            .post("products/sale-price/get", sendData)
            .then((result) => {
              this.varints[this.variantIndex].offerBDel.amount =
                this.variantItem.offerBDel.amount == 0
                  ? null
                  : this.variantItem.offerBDel.amount;
              this.varints[this.variantIndex].offerBDel.type =
                this.offerTypeSelect == null
                  ? null
                  : this.offerTypeSelect.value;
              this.varints[
                this.variantIndex
              ].voriginal_price = this.variantItem.voriginal_price;
              this.varints[this.variantIndex].offerBDel.startdate =
                this.variantItem.offerBDel.startdate == null
                  ? null
                  : this.variantItem.offerBDel.startdate;
              this.varints[this.variantIndex].offerBDel.enddate =
                this.variantItem.offerBDel.enddate == null
                  ? null
                  : this.variantItem.offerBDel.enddate;
              this.varints[this.variantIndex].offerBDel.usernumbers =
                this.variantItem.offerBDel.usernumbers == null
                  ? null
                  : this.variantItem.offerBDel.usernumbers;
              this.varints[this.variantIndex].profit = result.data.profit;
              this.varints[this.variantIndex].salePrice =
                result.data.sale_price;
              this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
              });
            })
            .catch((err) => {
              this.errors_back2.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back2.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back2 = err.response.data.data;
                }
                this.showDismissibleAlert2 = true;
              } else {
                this.errors_back2 = [];
                this.errors_back2.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert2 = true;
              }
            });
        } else {
        }
      },
      getAttrValue() {
        this.errors_back = [];
        this.showDismissibleAlert = false;
        axios
          .get(
            "products/items/" +
              this.itemSelect.value +
              "/reports/" +
              this.productData.serialnumber +
              "/valid-data"
          )
          .then((result) => {
            const data = result.data.data.attributes;
            const item = result.data.data.item;
            this.attrOptions = [];
            this.attrSelect = [];
            this.productData.attributes = [];
            this.valuesOptions = [];
            this.valuesSelect = [];
            this.combTextArr = [];
            this.combValuesArr = [];
            this.attr2Options = [];
            if (data.length > 0) {
              for (let attr in data) {
                if (data[attr].translation != null) {
                  this.attrOptions.push({
                    value: data[attr].id.toString(),
                    text: data[attr].translation.name,
                    slug: data[attr].translation.slug,
                  });
                  this.attrSelect.push({
                    value: data[attr].id.toString(),
                    text: data[attr].translation.name,
                    slug: data[attr].translation.slug,
                  });
                  this.productData.attributes.push({
                    id: "",
                    values: [],
                  });
                  this.valuesOptions.push([]);
                  for (let val in data[attr].values) {
                    this.valuesOptions[attr].push({
                      text: data[attr].values[val].translation.name,
                      value: data[attr].values[val].id.toString(),
                    });
                  }
                  if (
                    data[attr].values.filter((e) => e.valid === true).length > 0
                  ) {
                    for (let val in data[attr].values) {
                      if (
                        data[attr].values[val].valid == true ||
                        data[attr].values.length == 1
                      ) {
                        this.valuesSelect.push({
                          text: data[attr].values[val].translation.name,
                          value: data[attr].values[val].id.toString(),
                        });
                      }
                    }
                  } else {
                    this.valuesSelect.push([]);
                  }
                }

                this.combTextArr.push([]);
                this.combValuesArr.push([]);
              }
              this.attr2Options = this.attrOptions;
              this.categorySelect = {
                text: item.category.translation.name,
                value: item.category.id.toString(),
              };
              (this.itemSelect.name = item.item_translations.name),
                (this.itemSelect.description =
                  item.item_translations.description),
                (this.itemSelect.short_description =
                  item.item_translations.short_description),
                (this.itemSelect.status = item.status),
                (this.itemSelect.cover = item.cover);
              this.tagSelect = [];
              for (let indeex in item.tags) {
                this.tagSelect.push({
                  text: item.tags[indeex].name,
                  value: item.tags[indeex].id.toString(),
                });
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      generate() {
        for (let val in this.valuesSelect) {
          const vale = this.valuesSelect[val];
          this.productData.attributes[val].values = [];
          if (this.productData.is_new == "used") {
            this.productData.attributes[val].values.push(vale.value);
          } else {
            for (let valID in vale) {
              this.productData.attributes[val].values.push(vale[valID].value);
            }
          }
        }

        for (let att in this.productData.attributes) {
          const attrbu = this.productData.attributes[att];
          attrbu.id = this.attrSelect[att].value;
        }
        for (let val in this.valuesSelect) {
          const vale = this.valuesSelect[val];
          this.combTextArr[val].length = 0;
          if (this.productData.is_new == "used") {
            this.combTextArr[val].push(vale.text);
          } else {
            for (let valID in vale) {
              this.combTextArr[val].push(vale[valID].text);
            }
          }
        }
        for (let val in this.valuesSelect) {
          const vale = this.valuesSelect[val];
          this.combValuesArr[val] = [];
          if (this.productData.is_new == "used") {
            this.combValuesArr[val].push(vale.value);
          } else {
            for (let valID in vale) {
              this.combValuesArr[val].push(vale[valID].value);
            }
          }
        }
        const compainText = this.getCombn(this.combTextArr);
        const compainValues = this.getCombn2(this.combValuesArr);
        for (let tran in this.varints) {
          // if (this.productData.original_price != '' && this.productData.original_price != null) {
          //     this.varints[tran].voriginal_price = this.productData.original_price
          //     let price = parseFloat(this.varints[tran].voriginal_price)
          //     let profit = (price * this.vendorCommissionRate) / 100
          //     this.varints[tran].salePrice = price + profit
          //     this.varints[tran].amountPriceDis = false
          // } else {
          //     this.varints[tran].voriginal_price = this.productData.original_price
          //     this.varints[tran].amountPriceDis = true
          // }
          this.varints[tran].vis_new = this.productData.is_new;
        }
        this.subActive = false;
      },
      // make combination to get names of variants
      getCombn(arr) {
        if (arr.length == 1) {
          var ans = [];
          for (let ind in arr[0]) {
            ans.push(arr[0][ind]);
          }
          this.warrantySelect.length = 0;
          this.warrantyDurationSelect.length = 0;
          this.varints.length = 0;
          for (let inde in ans) {
            this.warrantySelect.push();
            this.warrantyDurationSelect.push();
            this.varints.push({
              vname: ans[inde],
              vquantity: "1",
              vis_new: "used",
              warranty: {
                type: null,
                duration: null,
                end_date: null,
                agency_file: null,
              },
              voriginal_price: "",
              salePrice: "",
              amountPriceDis: true,
              report_serial_number: "",
              vtranslations: [
                {
                  vlocale1: {
                    text: "English",
                    value: "en",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
                {
                  vlocale1: {
                    text: "Arabic",
                    value: "ar",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
              ],
              offerHide: false,
              offerBDel: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "1",
              },
              offer: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "",
              },
              vimages: [
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
              ],
              vimages3: [
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
              ],
              vimages2: [
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
              ],
            });
          }
          return ans;
        } else {
          var ans = [];
          // recur with the rest of the array.
          var otherCases = this.getCombn(arr.slice(1));
          for (var i = 0; i < otherCases.length; i++) {
            for (var j = 0; j < arr[0].length; j++) {
              ans.push(arr[0][j] + "-" + otherCases[i]);
            }
          }
          this.warrantySelect.length = 0;
          this.varints.length = 0;
          this.warrantyDurationSelect.length = 0;
          for (let ind in ans) {
            this.warrantySelect.push();
            this.warrantyDurationSelect.push();
            this.varints.push({
              vname: ans[ind],
              vquantity: "1",
              voriginal_price: "",
              salePrice: "",
              vis_new: "used",
              warranty: {
                type: null,
                duration: null,
                end_date: null,
                agency_file: null,
              },
              amountPriceDis: true,
              report_serial_number: "",
              vtranslations: [
                {
                  vlocale1: {
                    text: "English",
                    value: "en",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
                {
                  vlocale1: {
                    text: "Arabic",
                    value: "ar",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
              ],
              offerHide: false,
              offerBDel: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "1",
              },
              offer: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "",
              },
              vimages: [
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
                {
                  vname: "",
                },
              ],
              vimages3: [
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
                {
                  vname: [],
                },
              ],
              vimages2: [
                {
                  name: "صورة شاشة الموبايل",
                  vname: "",
                },
                {
                  name: "صورة ظهر الموبايل",
                  vname: "",
                },
                {
                  name: "صورة قاعدة الموبايل",
                  vname: "",
                },
                {
                  name: "صورة رأس الموبايل",
                  vname: "",
                },
                {
                  name: "صورة الجانب الأيسر الموبايل",
                  vname: "",
                },
                {
                  name: "صورة زر التشغيل الموبايل",
                  vname: "",
                },
                {
                  name: "صورة علبة وملحقات الموبايل",
                  vname: "",
                },
              ],
            });
          }
          return ans;
        }
      },
      // make combinations to get values of variants
      getCombn2(arr) {
        if (arr.length == 1) {
          var ans = [];
          for (let ind in arr[0]) {
            ans.push(arr[0][ind]);
          }
          for (let inde in ans) {
            this.varints[inde].values = [ans[inde]];
          }
          return ans;
        } else {
          var ans = [];
          // recur with the rest of the array.
          var otherCases = this.getCombn2(arr.slice(1));
          for (var i = 0; i < otherCases.length; i++) {
            for (var j = 0; j < arr[0].length; j++) {
              ans.push(arr[0][j] + "-" + otherCases[i]);
            }
          }
          for (let ind in ans) {
            this.varints[ind].values = ans[ind];
          }
          return ans;
        }
      },

      fileUpload2(event, index) {
        this.varints[index].warranty.agency_file = event.target.files[0];
      },
      // set attrbuites
      getOptions() {
        this.attr2Options = this.attrOptions.filter(
          (item) => !this.attrSelect.includes(item)
        );
      },
      getAccessories() {
        axios
          .get("accessories")
          .then((result) => {
            const data = result.data.data;
            for (let index in data) {
              if (data[index].translation != null) {
                this.accessoriesOptions.push({
                  text: data[index].translation.name,
                  value: data[index].id.toString(),
                });
              } else {
                this.accessoriesOptions.push({
                  text: "undefinded",
                  value: data[index].id.toString(),
                });
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      // upload function vimages of item
      uploadImage3(event, item, index) {
        let files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        } else {
          this.createImage3(files[0], index, item);
        }
      },
      createImage3(file, ind, item) {
        let reader = new FileReader();
        reader.onload = (e) => {
          item.vimages2[ind].vname = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      validationForm() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then((success) => {
            if (success) {
              if (this.errors_back.length == 0) {
                resolve(true);
              } else {
                reject();
              }
            } else {
              reject();
            }
          });
        });
      },
      validationForm2() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules2.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      getItems() {
        // this.itemSelect = null
        if (this.brandSelect != null) {
          this.itemOptions = [
            {
              value: "select_value",
              text: "Select Value",
            },
          ];
          axios
            .get("products/brands/" + this.brandSelect.value + "/items/get")
            .then((result) => {
              const data = result.data.data;
              this.itemOptions.lenght = 0;
              if (data.length > 0) {
                for (let ind in data) {
                  if (data[ind].item_translations != null) {
                    this.itemOptions.push({
                      text: data[ind].item_translations.name,
                      value: data[ind].id.toString(),
                    });
                  }
                }
              }
              this.brendSe = false;
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      },
      searchResult($state) {
        if (this.productData.serialnumber.length >= 5) {
          this.showDismissibleAlert = false;
          this.currentPage += 1;
          // request Brand index API
          var url =
            "diagnostic/reports-pagi/10/sortBy-id-desc/" +
            this.productData.serialnumber +
            "?page=" +
            this.currentPage;
          axios
            .get(url)
            .then((result) => {
              const data = result.data.data.data;
              if (data.length > 0) {
                for (let index in data) {
                  this.serialsNumber.push({
                    name: data[index].serial_number,
                  });
                }
                if (this.serialsNumber.length > 0) {
                  this.resultShow = true;
                } else {
                  this.noResult = true;
                  this.resultShow = false;
                }
                if (this.serialsNumber.length > 10) {
                  $state.loaded();
                } else {
                  $state.complete();
                }
              } else {
                $state.complete();
              }
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      },
      searchResultButton($state) {
        if (this.productData.serialnumber.length >= 5) {
          this.showDismissibleAlert = false;
          this.serialsNumber.length = 0;
          this.currentPage = 1;
          // request Brand index API
          var url =
            "diagnostic/reports-pagi/10/sortBy-id-desc/" +
            this.productData.serialnumber +
            "?page=" +
            this.currentPage;
          axios
            .get(url)
            .then((result) => {
              const data = result.data.data.data;
              if (data.length > 0) {
                for (let index in data) {
                  this.serialsNumber.push({
                    name: data[index].serial_number,
                  });
                }
                if (this.serialsNumber.length > 0) {
                  this.resultShow = true;
                } else {
                  this.noResult = true;
                  this.resultShow = false;
                }
                $state.loaded();
              } else {
                $state.complete();
              }
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      },
      vImagesRepeateAgain(item) {
        //  this.refheightForm = this.$refs.pricesForm
        item.vimages.push({
          vname: "",
        });
        item.vimages2.push({
          name: "صورة",
          vname: "",
        });
        item.vimages3.push({
          vname: [],
        });
      },
      vImagesRemoveItem(index, item) {
        if (item.vimages2.length <= 1) {
          this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
            title: "Alert",
            size: "sm",
            okVariant: "danger",
            okTitle: "Accept",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          });
        } else {
          item.vimages2.splice(index, 1);
          item.vimages3.splice(index, 1);
          item.vimages.splice(index, 1);
        }
      },
      checkCategory(){
        var type = '';
        if(this.$route.params.type == "Portal"){
          type ='is_portal_used';
        }else{
          type ='is_instore_used';
        }
            axios
            .post("/checkCategory/" + this.$route.params.category_id,{type: type})
            .then((result) => {
              const data = result.data.data;

                if(data == true){
                  this.showPage = true;
                }else{
                  this.$router.push({
                    path: '/Product/Add' 
                  })
                }
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
      },
      getInstallmentPlans(){
      axios
        .get('products/installment/plans')
        .then((result) => {
          const data = result.data.data;
          // console.log('result.data.data',result.data.data);
          this.InstallmentPlans = data;
        })
        .catch((err) => {
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
    },
    numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  calculateInstallmentPrice(voriginal_price,interestRate ,fees_type,admin_fees, months) {
      // const interest = (voriginal_price * interestRate) / 100;
      // const totalWithInterest = voriginal_price + interest;
      // return Math.round(totalWithInterest / months);

      
      let totalVariantPrice;
      const voriginal_pricenum = Number(voriginal_price);
      const admin_feesnum = Number(admin_fees);
      const interestRatenum = Number(interestRate);
      

        if (fees_type == 'fixed') {
          // If fees are fixed, add admin fees directly to the original price
          totalVariantPrice = voriginal_pricenum + admin_feesnum;
        } else {
          // If fees are percentage-based
          if (admin_feesnum != 0) {
            totalVariantPrice = voriginal_pricenum + (voriginal_pricenum * admin_feesnum) / 100;
          } else {
            totalVariantPrice = voriginal_pricenum; // No admin fees
          }
        }

        // Add interest to the total price
        const totalWithInterest = totalVariantPrice + (totalVariantPrice * interestRatenum) / 100;
        // Calculate installment price
        return Math.round(totalWithInterest / months);

    },
      vendorsPriceRates() {
       // request roles index
          axios
            .get('price-rates/index')
            .then((result) => {
              //console.log(result)
              // const data = result.data.data

              // this.PriceRates = []
              this.PriceRates = result.data.data
        
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        },
      isString(value) {
        return typeof value === "string" || value instanceof String;
      },
    },
    created() {
      this.checkCategory();
      this.getAccessories();
      axios
        .get("products/reports/validate-reports")
        .then((result) => {
          const data = result.data.data;
          for (let index in data) {
            this.reportOptions.push({
              // text: "اسم الهاتف: " + data[index].phone_name + ' سريال: ' + data[index].serial_number,
              text:
                data[index].serial_number +
                " :سريال " +
                " | " +
                data[index].marketName +
                " :اسم الهاتف ",
              value: data[index].serial_number,
            });
          }
          // axios
          //   .get("products/create")
          //   .then((result) => {
          //     const data = result.data.data;
          //     // set Brands

          //     for (let ind in data.brands) {
          //       this.brandOptions.push({
          //         text: data.brands[ind].translation.name,
          //         value: data.brands[ind].id.toString(),
          //       });
          //     }

          //     // // set Tags
          //     // if (data.tags.length > 0) {
          //     //     for (let ta in data.tags) {
          //     //         this.tagOptions.push({
          //     //             value: data.tags[ta].id.toString(),
          //     //             text: data.tags[ta].name
          //     //         })
          //     //     }
          //     // }
          //     // // set Attrbuites
          //     // if (data.attributes.length > 0) {
          //     //     for (let attr in data.attributes) {
          //     //         if (data.attributes[attr].translation != null) {
          //     //             this.attrOptions.push({
          //     //                 value: data.attributes[attr].id.toString(),
          //     //                 text: data.attributes[attr].translation.name
          //     //             })
          //     //         }
          //     //     }
          //     //     this.attr2Options = this.attrOptions
          //     // }
          //   })
          //   .catch((err) => {
          //     this.errors_back.length = 0;
          //     if (err.response.data.data != null) {
          //       if (this.isString(err.response.data.data)) {
          //         this.errors_back.push({
          //           error: err.response.data.data,
          //         });
          //       } else {
          //         this.errors_back = err.response.data.data;
          //       }
          //       this.showDismissibleAlert = true;
          //     } else {
          //       this.errors_back = [];
          //       this.errors_back.push({
          //         error: "internal server error",
          //       });
          //       this.showDismissibleAlert = true;
          //     }
          //   });
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });


        if(this.$route.params.type == 'In-Store'){
          if (localStorage.getItem('userData') != null) {
            const decryptedText = this.CryptoJS.AES.decrypt(
              localStorage.getItem("userData"),
              "EncryptDataMobileMasr"
            ).toString(this.CryptoJS.enc.Utf8);

            this.userData = JSON.parse(decryptedText);

          this.vendorsPriceRates();

          }
        };

        this.getInstallmentPlans();
    },
  };
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-wizard.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  #vs9__listbox {
    z-index: 99999 !important;
  }
  [dir="rtl"] .vs__selected-options {
    direction: rtl !important;
  }
  .vs__selected {
    color: black !important;
  }
  .vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
    min-width: 75px !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after {
    content: "\e843" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before {
    content: "\e844" !important;
  }
  @media only screen and (max-width: 425px) {
    .wizard-nav {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: row !important;
    }
    .attr-text {
      text-align: center;
      padding: 5px;
    }
  }


  


@media (max-width: 992px) and (min-width: 480px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 300px;
}
}


@media (max-width:480px) and (min-width: 361px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 80px;
  width: 200px;
}
}

@media (max-width: 360px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 80px;
  width: 180px;
}
}



@media (min-width:992px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 600px;
}
}



.change-padding .card-body {
    padding: 1rem !important;
  } 


  
  .price-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.price-info span {
  font-size: 15px; 
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .price-info {
    // flex-direction: column; /* Stack spans vertically */
    align-items: flex-start; /* Align text to the left */
  }

  .price-info span {
    font-size: 13px;
    white-space: normal; /* Allow text wrapping */
  }
}

@media (max-width: 576px) {
  .price-info span {
    font-size: 11px;
    margin-bottom: 3px;
  }
}

.installment-plans-card .card-body {
  padding: 10px !important;
}



@media (max-width: 768px) {
.installment-plans-card .card-body {
  padding: 5px !important;
}
}


.scrolling-container::-webkit-scrollbar {
  height: 10px !important;
}

.scrolling-container::-webkit-scrollbar-thumb {
  background-color: rgb(52 58 64/ 30%);
  border-radius: 10px;
}

.installment-plans-card .card-font {
  font-size: 12px;
}


.bg-sliver {
  background: #fafafa;
}

.max-width-42 {
  max-width: 42px;
}
</style>
